(($) => {
    $(document).ready(function () {

        //HEADER
        $('#menu-burger').click(function (){
            $('#header').toggleClass('open');
        });
        //MENU SUB-MENU
        $('#menu-header .menu-item.menu-item-has-children').click(function (){
            if(window.matchMedia(`(max-width: 1280px)`).matches){
                $(this).toggleClass('open');
            }
        });

        //SEARCH
        function toggleSearch(){
            $('#search').toggleClass('open');
            $('#searchform').stop().slideToggle(300);
            if($('#search').hasClass('open')){
                $('#searchform input[type="search"]').focus();
            }
        }

        $(document).keydown(function(event){
            if(event.ctrlKey && event.key === "k") {
                event.preventDefault();
                toggleSearch();
            }
        });

        $('#search').click(toggleSearch);

        //HOME SLIDER
        const optionsSlider = {
            item:1,
            slideMargin:0,
            controls:false,
            enableTouch:false,
            enableDrag:false,
            loop:true,
            mode:'fade',
            auto:true,
            pause:6000,
            startSlide: 0,
            pauseOnHover: true
        };
        if(window.matchMedia(`(max-width: 768px)`).matches){
            optionsSlider.adaptiveHeight = true;
        }
        $('#frontpage-header .slider').lightSlider(optionsSlider);

        //FOOTER LINKS
        $('#footer .nav-footer .link.has-sublinks>a').click(function (e){
            e.preventDefault();
            const parent = $(this).parent();
            if(parent.hasClass('active')){
                parent.removeClass('active');
                parent.find('.sublinks').slideUp(300);
            }else{
                //ALREADY ACTIVE
                const active = $('#footer .nav-footer .link.active');
                if(active.length){
                    active.removeClass('active');
                    active.find('.sublinks').slideUp(300);
                }
                parent.addClass('active');
                parent.find('.sublinks').slideDown(300);
            }
        });

        //WORKING AREA NAV
        const blockIntroNav = '#block-intro-nav';
        if($(blockIntroNav).length && window.matchMedia(`(min-width: 768px)`).matches){
            let navPos, winPos, navHeight, menuHeight;
            function refreshVar(){
                navPos = $(blockIntroNav).offset().top;
                navHeight = $(blockIntroNav).outerHeight(true);
                menuHeight = $('#header').outerHeight(true);
            }
            refreshVar();
            $(window).resize(refreshVar);

            $('<div class="clone-nav"></div>').insertBefore(blockIntroNav).css('height', navHeight).hide();

            $(window).scroll(function() {
                winPos = $(window).scrollTop();
                if (winPos+menuHeight >= navPos) {
                    $(blockIntroNav).addClass('scroll');
                    $('.clone-nav').show();
                } else {
                    $(blockIntroNav).removeClass('scroll');
                    $('.clone-nav').hide();
                }
            });
        }

        //MODAL
        $('.cd-modal-trigger').click(function (e) {
            e.preventDefault();
            var content = $(this).find('.modal--content').html();
            openModal(content);
        });
        $('.modal--bg, #modal .close').click(function () {
            closeModal();
        });
        function openModal(content) {
            $('#modal').find('.container').append(content);
            $('.modal--container').addClass('show');
            $('html, body').addClass('overflow-hidden');
        }
        function closeModal() {
            $('.modal--container').removeClass('show');
            $('html, body').removeClass('overflow-hidden');
            setTimeout(function () {
                $('#modal').find('.container').empty();
            }, 300);
        }

    });
})(jQuery);